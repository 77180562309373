import { setCookie } from "cookies-next";
import {
  signIn as signInService,
  signOut as signOutService,
} from "next-auth/react";
import {
  type TProvider,
  authSignIn,
  authSignOut,
} from "shared-utils/authentication";

import { AUTH } from "@/config/api";
import { PUBLIC } from "@/config/client";
export const isFirstSignIn = "___isFirstSignIn";

export const signOut = () => {
  setCookie(isFirstSignIn, true);
  localStorage.removeItem("access-management-form-storage");
  localStorage.removeItem("redirect-info-storage");

  return signOutService({
    redirect: false,
  }).finally(() => {
    authSignOut({ domain: AUTH.DOMAIN, baseUrl: PUBLIC.BASE_URL });
  });
};

type TSignIn = {
  callbackUrl: string;
};

export const signIn = async (provider: TProvider, props?: TSignIn) => {
  setCookie(isFirstSignIn, true);
  localStorage.removeItem("redirect-info-storage");

  const signInOptions = await authSignIn(provider, {
    ...props,
    baseUrl: PUBLIC.BASE_URL,
    domain: AUTH.DOMAIN,
  });
  if (signInOptions) {
    signInService("auth0", { ...signInOptions });
  }
};
